.start_screen {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.start_screen h1 {
    font-weight: normal;
}

.start_screen img {
    width: 300px;
    height: auto;
}

.start_bar {
    display: flex;
    justify-content: space-around;
    width: 25%;
}

.hover {
    text-decoration: none;
    color: var(--white);
    font-size: 36px;
}

.hover:hover {
    cursor: pointer;
    color: var(--yellow);
}