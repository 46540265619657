.fight_screen {
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
    gap: 8px;
}

.action_buttons {
    display: flex;
    width: 90%;
    justify-content: space-between;
}

.action_button {
    font-family: "Mercy";
    color: var(--orange);
    border: 4px solid var(--orange);
    width: 200px;
    height: 50px;
    /* padding: 6px 4px; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 24px;
    line-height: 1;
    cursor: pointer;
    background-color: transparent;
    letter-spacing: -2px;
}

.action_button img {
    width: 50px;
    height: auto;
}

.hover_action {
    border-color: var(--yellow);
    color: var(--yellow);
}

.action_button img {
    filter: invert(53%) sepia(74%) saturate(1267%) hue-rotate(340deg) brightness(100%) contrast(93%);
}

.hover_action img {
    filter: invert(99%) sepia(91%) saturate(1459%) hue-rotate(331deg) brightness(117%) contrast(99%);
}

.selected_action {
    border-color: var(--yellow);
    color: var(--yellow);
}

.selected_action img {
    width: 30px;
    height: auto;
    filter: none;
}

.user_bar {
    width: 90%;
    font-family: "Mars";
    display: flex;
    font-size: 36px;
    align-items: center;
    gap: 128px;
}

.hp_info {
    display: flex;
    align-items: center;
    gap: 24px;
}

.hp_bar {
    width: 60px;
    height: 30px;
    background-color: var(--yellow);
}

.console {
    width: 90%;
    height: 200px;
    border: 8px solid var(--white);
    padding: 8px 24px;
    font-size: 36px;
    line-height: 36px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 90%;
    height: 100%;
    position: relative;
}

.grid>div {
    border: 1px solid var(--green);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.console_content {
    display: flex;
    flex-wrap: wrap;
}

.console_choices {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.asterisk::before {
    content: '*';
    margin-right: 8px;
}

.selected,
.hover {
    color: var(--yellow);
    cursor: pointer;
}

.selected {
    position: relative;
    /* left: -28px; */
}

.selected::before {
    content: "";
    height: 20px;
    width: 20px;
    background-image: url('../assets/images/heart.png');
    background-size: contain;
    display: inline-block;
    margin-right: 8px;
}

.projects_console {
    display: grid;
    grid-template-columns: 4fr 3fr;
    grid-template-rows: repeat(2, 1fr);
    width: 90%;
    height: 100%;
}

.project_button {
    background: none;
    border: none;
    color: white;
    text-align: left;
    cursor: pointer;
}

#avatar {
    height: 150px;
    width: auto;
    image-rendering: pixelated;
    animation: bounce 2s infinite;
    animation-timing-function: ease-in-out;
}

@keyframes bounce {
    0% {
        transform: translateY(-10%)
    }

    50% {
        transform: translateY(10%)
    }

    100% {
        transform: translateY(-10%)
    }
}

.grid div:has(.dialogue_box) {
    align-items: flex-start;
}

.dialogue_box {
    background-color: var(--white);
    color: black;
    position: absolute;
    left: 10%;
    top: -50%;
    border: 2px solid black;
    padding: 12px;
    height: 200px;
    width: 500px;
    z-index: 2;
    font-family: "Pixel Comic Sans";
    font-size: 24px;
}

.pixel_corners {
    clip-path: polygon(0px calc(100% - 14px),
            2px calc(100% - 14px),
            2px calc(100% - 10px),
            4px calc(100% - 10px),
            4px calc(100% - 6px),
            6px calc(100% - 6px),
            6px calc(100% - 4px),
            10px calc(100% - 4px),
            10px calc(100% - 2px),
            14px calc(100% - 2px),
            14px 100%,
            calc(100% - 14px) 100%,
            calc(100% - 14px) calc(100% - 2px),
            calc(100% - 10px) calc(100% - 2px),
            calc(100% - 10px) calc(100% - 4px),
            calc(100% - 6px) calc(100% - 4px),
            calc(100% - 6px) calc(100% - 6px),
            calc(100% - 4px) calc(100% - 6px),
            calc(100% - 4px) calc(100% - 10px),
            calc(100% - 2px) calc(100% - 10px),
            calc(100% - 2px) calc(100% - 14px),
            100% calc(100% - 14px),
            100% 14px,
            calc(100% - 2px) 14px,
            calc(100% - 2px) 10px,
            calc(100% - 4px) 10px,
            calc(100% - 4px) 6px,
            calc(100% - 6px) 6px,
            calc(100% - 6px) 4px,
            calc(100% - 10px) 4px,
            calc(100% - 10px) 2px,
            calc(100% - 14px) 2px,
            calc(100% - 14px) 0px,
            14px 0px,
            14px 2px,
            10px 2px,
            10px 4px,
            6px 4px,
            6px 6px,
            4px 6px,
            4px 10px,
            2px 10px,
            2px 14px,
            0px 14px);
}

.arrow {
    position: absolute;
    border: solid 10px transparent;
    border-right-color: #FFF;
    z-index: 4;
    left: 2%;
    top: 25%;
}

.details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4px 8px;
    font-size: 24px;
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 2;
    background-color: #00003E;
    border: 1px solid white;
    border-radius: 8px;
}

.details a {
    color: var(--yellow)
}

.github_icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 50px;
    height: 50px;
    background-image: url('../assets/images/github.png');
    image-rendering: pixelated;
    z-index: 5;
}

.github_icon img {
    filter: invert(100%);
}